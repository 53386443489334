import React, {useEffect} from 'react'
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        // display: "none",
        position: "absolute",
        left: "0",
        "@media (max-width: 1025px)": {
            position: "relative",
            marginBottom: "40px"
        }
    },
    selectCity: {
        color: theme.palette.color.secondary,
        fontSize: "12px",
        lineHeight: "15px",
        cursor: "pointer",
        "@media (max-width: 1025px)": {
            fontSize: "18px",
            lineHeight: "21px"
        }
    },
    arrowDown: {
        marginLeft: "6px"
    }
}));

/**
 * Компонента выбора города
 * @module src/components/selectCity/index.js
 * @param {Object} props - объект свойств компонента React
 * @param {String} props.city - состояние города, который идёт в localStorage
 * @param {function} props.setCity - функция установки состояния города
 * @param {function} props.setOpenModal - функция изменения закрытия
 */

export default function SelectCity({city, setCity, setOpenModal}) {
    const classes = useStyles()

    // Если город не выбран, по умолчанию устанавливается Санкт-Петербург
    if (!city) {
        setCity("Санкт-Петербург")
        localStorage.setItem('city', "Санкт-Петербург")
    }

    useEffect(() => {
        if (city !== localStorage.getItem('city')){
            setCity(localStorage.getItem('city'))
        }

    }, [localStorage.getItem('city')])

    return (
        <div className={classes.root}>
            <button
                onClick={() => setOpenModal(true)}
                className={classes.selectCity}
            >
                {city}

                <svg className={classes.arrowDown} width="10" height="6" viewBox="0 0 10 6" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 0.999878L5 4.99988L1 0.999878" stroke="#BDBDC6" strokeLinecap="square"
                          strokeLinejoin="round"/>
                </svg>

            </button>
        </div>
    )
}
