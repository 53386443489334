import Modal from "@material-ui/core/Modal"
import { makeStyles, Slide, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import Cross from "./../../../static/svg/CrossPrimary.svg"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.color.main,
  },
  modal: {},
  container: {
    position: "relative",
    background: theme.palette.background.main,
    width: "90%",
    maxWidth: "1114px",
    maxHeight: "551px",
    minHeight: "507px",
    padding: "30px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "30vh",
    outline: "none",
    borderRadius: 20,
    scrollbarWidth: "none",
    "@media (max-width: 600px)": {
      width: "80%",
    },
    "&::-webkit-scrollbar": {
      width: 0,
      background: "transparent",
    },
  },
  closeButton: {
    position: "absolute",
    right: "30px",
    top: "30px",
    cursor: "pointer",
    border: "none",
    transition: ".15s ease all",
    padding: 0,
    "@media (max-width: 1025px)": {
      width: 20,
      height: 20,
    },
    "&:hover": {
      transform: "scale(1.05)",
      "@media (max-width: 1025px)": {
        transform: "scale(1.1)",
      },
    },
    "& svg": {
      display: "block",
      width: 20,
      height: 20,
    },
  },
  chooseCity: {
    fontWeight: 600,
    marginBottom: "16px",
  },
  chooseMark: {
    color: theme.palette.color.accentSecondary,
  },
  form: {
    display: "flex",
    justifyContent: "flex-start",
    background: theme.palette.background.secondary,
    borderRadius: "12px 12px 12px 12px",
    width: 600,
    height: 45,
    padding: "0px 0px 0px 16px",
    marginBottom: "40px",
    "@media (min-width: 1025px) and (max-width: 1279px)": {
      width: "46.875vw",
    },
    "@media (max-width: 767px)": {
      width: "90%",
    },
    "& input": {
      background: "none",
      border: "none",
      padding: 5,
      width: "calc(100% - 120px - 16px)",
      outline: "none",
      "@media (max-width: 767px)": {
        width: "100%",
      },
    },
    "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active": {
      boxShadow: `0 0 0 30px ${theme.palette.background.secondary} inset !important`,
    },
    "& input[type=text]": {
      fontSize: "14px !important",
      fontWeight: "Inter !important",
    },
  },
  img: {
    width: 16,
    height: "auto",
    display: "block",
    marginRight: 5,
    "@media (max-width: 1025px)": {
      width: 24,
      marginRight: 0,
    },
  },

  cityList: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    height: "322px",
    "@media (max-width: 767px)": {
      flexWrap: "nowrap",
      overflowY: "scroll",
    },
  },

  capital: {
    marginBottom: "20px",
    color: theme.palette.color.secondary,
    fontSize: "20px",
    lineHeight: "24px",
    cursor: "pointer",
    textAlign: "left",
  },

  cities: {
    marginBottom: "20px",
    color: theme.palette.color.secondary,
    fontSize: "16px",
    lineHeight: "19px",
    cursor: "pointer",
    textAlign: "left",
  },
}))

/**
 * Модальное окно выбора города
 * @module src/components/selectCity/selectCityModal
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученный из prismic
 * @param {String} props.city - состояние города, который идёт в localStorage
 * @param {function} props.setCity - функция установки состояния города
 * @param {boolean} props.openModal - true - окно открыто, false - окно закрыто
 * @param {function} props.setOpenModal - функция изменения закрытия
 */

export default function SelectCityModal({
  data,
  city,
  setCity,
  openModal,
  setOpenModal,
}) {
  const classes = useStyles()

  let cities = data.allPrismicCities.nodes[0]?.data.cities.map(
    city => city.city
  )

  const capitals = cities.filter(
    isCapital =>
      isCapital.toLowerCase().trim() === "москва" ||
      isCapital.toLowerCase().trim() === "санкт-петербург"
  )
  cities = cities.filter(capital => !~capitals.indexOf(capital))

  const [citiesRender, setCitiesRender] = useState(cities.slice(0))
  const [capitalsRender, setCapitalsRender] = useState(capitals.slice(0))
  const [input, setInput] = useState("")

  let inputCheck = new RegExp(`${input}`, "gi")

  const resetRender = () => {
    setCitiesRender(cities.slice(0))
    setCapitalsRender(capitals.slice(0))
  }

  const handleSelect = e => {
    setCity(() => e.target.innerText)
    localStorage.setItem("city", e.target.innerText)
    setTimeout(() => {
      setOpenModal(false)
      window.location.reload()
    }, 600)
    setInput("")
  }

  const checkCity = e => {
    setCitiesRender(cities.filter(city => city.match(inputCheck)))
    setCapitalsRender(capitals.filter(capital => capital.match(inputCheck)))

    if (e.key === "Enter") {
      if (e.target.value === false) {
        resetRender()
      } else if (
        citiesRender[0]?.match(inputCheck) &&
        citiesRender.length === 1 &&
        capitalsRender.length === 0
      ) {
        setCity(citiesRender[0])
        localStorage.setItem("city", citiesRender[0])
        setTimeout(() => {
          setOpenModal(false)
          window.location.reload()
        }, 300)
        resetRender()
        setInput("")
      } else if (
        capitalsRender[0]?.match(inputCheck) &&
        capitalsRender.length === 1 &&
        citiesRender.length === 0
      ) {
        setCity(capitalsRender[0])
        localStorage.setItem("city", capitalsRender[0])
        setTimeout(() => {
          setOpenModal(false)
          window.location.reload()
        }, 300)
        resetRender()
        setInput("")
      }
    }
  }

  const onChange = e => {
    setInput(e.target.value)
    inputCheck = new RegExp(`${input}`, "gi")
  }

  useEffect(() => {
    setCitiesRender(cities.filter(city => city.match(inputCheck)))
    setCapitalsRender(capitals.filter(capital => capital.match(inputCheck)))
  }, [input])

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
        className={classes.modal}
        open={openModal}
        disableAutoFocus={false}
        onClose={() => setOpenModal(false)}
      >
        <Slide direction="up" in={openModal} mountOnEnter unmountOnExit>
          <div className={classes.container}>
            <button
              className={classes.closeButton}
              onClick={() => setOpenModal(false)}
            >
              <Cross />
            </button>
            <Typography variant="h4" className={classes.chooseCity}>
              Выберете <span className={classes.chooseMark}>город</span>
            </Typography>
            <div className={classes.form}>
              <img
                src={
                  data.allPrismicHeader.edges[0]?.node.data.find_img.localFile
                    .publicURL
                }
                alt={
                  data.allPrismicHeader.edges[0]?.node.data.find_img.alt ??
                  "img"
                }
                className={classes.img}
                width={16}
                height={16}
              />
              <input
                type="text"
                onKeyDown={e => checkCity(e)}
                onChange={onChange}
                placeholder={city}
                name="searchCity"
                autoFocus
              />
            </div>
            <div className={classes.cityList}>
              {capitalsRender?.map((capital, i) => (
                <button
                  className={classes.capital}
                  key={`capital ${i}`}
                  onClick={e => handleSelect(e)}
                >
                  {capital}
                </button>
              ))}
              {citiesRender?.map((city, i) => (
                <button
                  className={classes.cities}
                  onClick={e => handleSelect(e)}
                  key={`city ${i}`}
                >
                  {city}
                </button>
              ))}
            </div>
          </div>
        </Slide>
      </Modal>
    </div>
  )
}
