import React from "react"
import { makeStyles } from "@material-ui/styles"
import DefaultLink from "./link/default"
import SelectCity from "../../selectCity"
import SaleButton from "./link/sale"
import { navigate } from "gatsby"
import CatalogButton from "./catalog"
import ButtonWithIcon from "./buttonWithIcon"
import Search from "./search"

import { GlobalStateContext } from "../../../context/GlobalContextProvider"

const useStyles = makeStyles(theme => ({
  root: {
    height: 100,
    background: "white",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 1025px)": {
      height: 70,
    },
  },
  wrapper: {
    width: "100%",
    zIndex: 1001,
  },
  submenu: {
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 8,
    "@media (max-width: 1025px)": {
      display: "none",
    },
  },
  menu: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    padding: 0,
    border: "none",
    background: "transparent",
    minWidth: 0,
    minHeight: 0,
    cursor: "pointer",

    "@media (min-width: 1025px) and (max-width: 1279px)": {
      width: "17.8vw",
    },
    "@media (max-width: 1025px)": {
      height: 36,
      width: "auto",
    },
    "@media (max-width: 320px)": {
      height: "auto",
      width: "40vw",
    },
    "& img": {
      display: "block",
      transition: ".15s ease all",
      width: "auto",
      height: 52,
      "@media (min-width: 1025px) and (max-width: 1279px)": {
        width: "17.8vw",
      },
      "@media (max-width: 1025px)": {
        height: 36,
        width: "auto",
      },
      "@media (max-width: 320px)": {
        height: "auto",
        width: "40vw",
      },
    },
    "&:hover": {
      "& img": {
        transform: "translateY(-4px)",
      },
    },
  },
  catafind: {
    display: "flex",
    alignItems: "center",
    "@media (max-width: 1025px)": {
      flexDirection: "row-reverse",
    },
    "& .catalog": {
      marginRight: 28,
      "@media (min-width: 1025px) and (max-width: 1279px)": {
        marginRight: "1.4vw",
      },
      "@media (max-width: 1025px)": {
        marginLeft: 20,
        marginRight: 0,
      },
      "@media (max-width: 252px)": {
        marginLeft: "2vw",
      },
    },
  },
  background: {
    background: "white",
    zIndex: 999,
    position: "absolute",
    width: "100vw",
    height: 100,
    top: 0,
    left: 0,
    "@media (max-width: 1025px)": {
      height: 70,
    },
  },
}))

/**
 * Шапка сайта
 * @module src/components/layout/header
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученый из prismic
 * @param {boolean} props.catalog - состояние каталога, true = открыт
 * @param {function} props.setCatalog - функция установки состояния каталога
 * @param {boolean} props.animation - true - анимация открытия, false - анимация закрытия
 * @param {function} props.setAnimation - функция изменения анимации
 */

export default function Header({
  data,
  catalog,
  setCatalog,
  animation,
  setAnimation,
  city,
  setCity,
  setOpenModal,
}) {
  const classes = useStyles()
  const state = React.useContext(GlobalStateContext)

  // const links = data.allPrismicHeader?.edges[0]?.node.data.body
  const links = data.allPrismicHeader?.edges[0]?.node.data.body.filter(
    link => !link.primary.link_name.text.match(/Выкуп|Перепродажа/gi)
  )

  return (
    <header id="header" className={classes.root}>
      <div className={classes.wrapper}>
        <nav className={classes.submenu}>
          <SelectCity
            city={city}
            setCity={setCity}
            setOpenModal={setOpenModal}
          />

          <SaleButton
            name={data.allPrismicHeader?.edges[0]?.node.data.sale_name.text}
            link="/sales/"
          />

          {links?.map((links, i) => (
            <DefaultLink
              key={`info ${i}`}
              name={links.primary.link_name.text}
              link={`/${links.primary.link}`}
            />
          ))}
          <DefaultLink
            key={`info ${links.length}`}
            name="Продавайте на Krypton"
            link={`/seller-form`}
          />
        </nav>
        <nav className={classes.menu}>
          <button onClick={() => navigate("/")} className={classes.logo}>
            <img
              src={
                data.allPrismicHeader.edges[0]?.node.data.logo_svg.localFile
                  .publicURL
              }
              alt={
                data.allPrismicHeader.edges[0]?.node.data.logo_svg.alt ?? "logo"
              }
              height={158}
              width={52}
              style={{ width: "auto", height: "100%" }}
            />
          </button>

          <div className={classes.catafind}>
            <CatalogButton
              data={data}
              catalog={catalog}
              setCatalog={setCatalog}
              animation={animation}
              setAnimation={setAnimation}
            />

            <Search data={data} />
          </div>

          <ButtonWithIcon
            name={data.allPrismicHeader.edges[0]?.node.data.favorites_name.text}
            link={"/favorites/"}
            img={
              data.allPrismicHeader.edges[0]?.node.data.favorites_img.localFile
                .publicURL + "#outline"
            }
            alt={
              data.allPrismicHeader.edges[0]?.node.data.favorites_img.alt ??
              "favorites"
            }
            count={state.favorites.length}
          />

          <ButtonWithIcon
            name={data.allPrismicHeader.edges[0]?.node.data.cart_name.text}
            link={"/cart/"}
            img={
              data.allPrismicHeader.edges[0]?.node.data.cart_img.localFile
                .publicURL + "#outline"
            }
            alt={
              data.allPrismicHeader.edges[0]?.node.data.cart_img.alt ?? "cart"
            }
            count={state.cart.length}
          />
        </nav>
      </div>
      <div className={classes.background} />
    </header>
  )
}
