const djangoRequests = {
  getPrice: async (uids) => {
    const urlCheckPrice = `${process.env.API_URL}/api/product/get-price`
    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    const body = JSON.stringify({
      products: uids,
      city: localStorage.getItem("city"),
    })

    const init = {
      method: "POST",
      headers,
      body,
    }

    const request = await fetch(urlCheckPrice, init)
    const prices = await request.json()
    return prices
  },
}


export default djangoRequests